@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.info {
  width: 100%;
  text-align: right;
  font-size: 26px;
}
a {
  position: relative;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #ffe600 !important;
}
a.outlined {
  font-weight: bold;
  transition: all 0.3s ease;
}
a:not(.outlined):after {
  content: "";
  height: 1px;
  background: #ffe600;
  position: absolute;
  width: 0;
  transition: all 0.5s ease;
  z-index: 1;
  left: 0;
  bottom: -2px;
}
a:hover::after {
  width: 100%;
}
.info a,
.info i {
  color: #ffe600;
}
.info i {
  margin-right: 10px;
}

.header {
  /* padding: 100px; */
  padding-top: 2rem;
  padding-bottom: 10px;
  display: flex;
  width: auto;
  border-bottom: 1px solid #ffe600;
}

.header .logo {
  min-width: 255px;
  width: 30%;
  font-size: 3rem;
  font-family: "Bebas Neue", Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  color: #ffe600;
  margin: 0 auto;
}
.header nav {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
}

nav li {
  margin: 0 20px;
  padding: 5px;
  list-style-type: none;
  text-align: center;
}

nav li a {
  text-decoration: none;
  color: #ffe600;
  font-size: 1.3em;
  text-transform: uppercase;
  transition: all 0.3s ease;
  font-weight: normal;
}

nav li a::before {
  display: block;
  content: attr(title);
  font-weight: bold;
  font-size: 1.3em;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

nav li a:hover {
  font-weight: bold;
}
nav li a.active {
  font-weight: bold;
}

.header-title h2 {
  display: block;
  height: 0px;
  transition: all 0.2s ease-in;
}

.header-title h2.active {
  height: 35px;
  color: #ffe600;
  font-size: 1.3em;
}

.card-title {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 1.3rem;
  margin-top: 15px;
  font-family: "Bebas Neue", Verdana, Geneva, Tahoma, sans-serif;
}

.wrapper {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)),
    url("./images/technology-bg.jpeg");
  background-size: cover;
  min-height: 100vh;
}

.site-footer {
  /* background-color: #26272b; */
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #cccccc;
  text-align: left;
}
.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}
.site-footer hr.small {
  margin: 20px 0;
}
.site-footer h6 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}
.site-footer a {
  color: #cccccc;
}
.site-footer a:hover {
  color: #3366cc;
  text-decoration: none;
}
.footer-links {
  padding-left: 0;
  list-style: none;
}
.footer-links li {
  display: block;
}
.footer-links a {
  color: #efc221;
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #3366cc;
  text-decoration: none;
}
.footer-links.inline li {
  display: inline-block;
}
.site-footer .social-icons {
  text-align: right;
}
.site-footer .social-icons i {
  color: white;
  font-size: 30px;
}
.site-footer .social-icons a {
  width: 45px;
  height: 45px;
  line-height: 55px;
  margin-left: 15px;
  margin-right: 0;
  border-radius: 100%;
  background-color: transparent;
  transform: translate(-15px);
}
/* .site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #33353d;
} */
.copyright-text {
  margin: 0;
  text-align: left;
}
h2.active.basic-header {
  margin: 0 !important;
  font-size: 1 rem;
  text-align: center;
  color: white !important;
}

@media (max-width: 991px) {
  .site-footer [class^="col-"] {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .cardbox {
    grid-template-columns: 50% 50% !important;
  }
  .site-footer {
    padding-bottom: 0;
  }
  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}
.header-title {
  text-align: right;
  text-transform: uppercase;
  font-size: 2rem;
  margin: 2em;
}
.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.social-icons li {
  display: inline-block;
  margin-bottom: 4px;
}
.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px;
}
.social-icons a {
  background-color: #eceeef;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe;
}
.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}
.social-icons a.facebook:hover {
  background-color: #3b5998;
}
.social-icons a.twitter:hover {
  background-color: #00aced;
}
.social-icons a.linkedin:hover {
  background-color: #007bb6;
}
.social-icons a.dribbble:hover {
  background-color: #ea4c89;
}
@media (max-width: 767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
}

@media (max-width: 420px) {
  .info {
    text-align: center;
  }
  .header {
    flex-direction: column;
  }
  .cardbox {
    grid-template-columns: 50% 50% !important;
  }
  h2.active.basic-header {
    margin: 0 !important;
    font-size: 12px;
    text-align: center;
    color: white !important;
  }
  .header-title {
    text-align: right;
    text-transform: uppercase;
    font-size: 2rem;
    margin: 5px 0;
  }
  .card {
  }
}

.map-container {
  margin-top: 15px;
}
