@import "./css/noscript.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.main {
  overflow: hidden;
  position: relative;
}
.information {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  /* transition: all 1s ease; */
}
.cardbox.flipped {
  opacity: 0;
  transition: all 0.1s ease;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  margin: 0;
  padding: 0;
}
.cardbox {
  position: relative;
  display: grid;
  grid-template-columns: 33.333% 33.333% 33.333%;
  height: 100%;
  /* min-height: 100vh; */
  max-width: 1200px;
  margin: 0 auto;
  /* transition: all 1s ease; */
}
.close-btn {
  position: absolute;
  right: -14px;
  top: -20px;
  z-index: 999;
}
.card {
  position: relative;
  padding: 2%;
  box-shadow: 10px 10px 10px #010000;
  width: 100%;
  height: 100%;
  transition: all 1s ease;
}
.formatted {
  margin: 0;
  padding: 0;
  box-shadow: none;
}
.formatted > .front > .cardcontent.main {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.overlay {
  margin-bottom: 50px;
}
.card.active {
}
.card.inactive {
}

/* .front {
  transform: rotateY(0deg);
}
.back {
  transform: rotateY(180deg);
} */
.card:hover .front {
  cursor: pointer;
  /* transform: scale(1.2); */
  /* transform: rotateY(-180deg); */
  transition-delay: 0s;
}
/* .card:hover .back {
  transform: rotateY(0deg);
  transition-delay: 0s;
} */
.cardcontent {
  margin: 0;
  text-align: center;
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  -webkit-flex-direction: column;
  -webkit-justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: sans-serif;
  font-size: 1.25rem;
  padding: 2rem;
}

.front {
  height: 100%;
}

.card1 .front {
  background: #bf304a;
}
.card1 .back {
  background: #801a2c;
}

.card2 .front {
  background: #30a3bf;
}
.card2 .back {
  background: #1a6b80;
}

.card3 .front {
  background: #f29524;
}
.card3 .back {
  background: #b36a12;
}

.card4 .front {
  background: #88bf37;
}
.card4 .back {
  background: #57801a;
}

.card5 .front {
  background: #008afb;
}
.card5 .back {
  background: #57801a;
}
.card6 .front {
  background: #9f00fb;
}
.card6 .back {
  background: #57801a;
}
